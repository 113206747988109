<script setup>
import { reactive } from 'vue'
import { useSidePanel } from '@/stores/sidepanel'
import SidePanelMenu from '@/components/layout/panel/SidePanelMenu.vue'

const sidePanelStore = useSidePanel()

const items = reactive([
  {
    label: 'Settings',
    icon: 'file-pen',
    tab: 'settings'
  },
  {
    label: 'Bids',
    icon: 'folder-open',
    tab: 'bids'
  },
  {
    label: 'Activities',
    icon: 'history',
    tab: 'activities'
  }
])
</script>

<template>
  <div class="w-full h-screen flex flex-col">
    <div
      class="md:hidden px-4 h-[60px] w-full m-0 flex flex-row justify-between items-center border-b border-cement-600"
    >
      <span class="text-[20px] semi-bold">Menu</span>
      <font-awesome-icon
        icon="times"
        size="lg"
        class="cursor-pointer"
        @click.native="sidePanelStore.toggle"
      />
    </div>
    <SidePanelMenu class="mt-4" :model="items" :allowStarred="false" />
  </div>
</template>
